<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          ユーザーマスタ
        </CCardHeader>
        <vue-confirm-dialog></vue-confirm-dialog>

        <CCardBody>
          <div>
            <CRow>
              <CCol sm="4">
                <CInput
                    id="name"
                    label="名前"
                    type="text"
                    horizontal
                    v-model="name"
                />
              </CCol>
              <CCol sm="4">
                <CInput
                    id="email"
                    label="Email"
                    type="text"
                    horizontal
                    v-model="email"
                />
              </CCol>
              <CCol sm="4">
                <CSelect
                    id="shop"
                    label="店舗"
                    horizontal
                    v-model="shopSelected"
                    :options="shopOptions"
                    @change="selectShopChange"
                    placeholder="選択する"
                />
              </CCol>
            </CRow>

            <CRow class="mb-3">
              <CCol sm="12">
                <CButton color="primary" class="mr-2" @click="filterData">検索</CButton>
                <CButton color="danger" @click="resetFilterData">取り消す</CButton>
              </CCol>
            </CRow>
          </div>
          <CRow class="text-right mb-2">
            <CCol sm="12">
              <CButton v-if="userShopId === WAREHOUSUE" color="primary" class="mr-2" @click="renderAddUser">ユーザー追加</CButton>
            </CCol>
          </CRow>

          <div v-if="loading">
            <LoadingCompoment />
          </div>
          <div v-else :key="key">
            <DataTable
                hover
                striped
                border
                :items="users"
                :fields="fields"
                :items-per-page="perPage"
                :active-page="page"
                :pagination-data="paginationData"
                @page-change="pageChange"
                @pagination-change="selectChangePerpage"
                :pagination="{ doubleArrows: false, align: 'center'}"
                :items-per-page-select="{
                  label: '表示件数',
                  values: [5, 10, 20, 30, 50, 100],
              }"
            >
              <div slot="select-header">
                <CInputCheckbox
                    id="checkAll"
                    label=""
                    class="pb-3"
                    name="listSelected"
                    @update:checked="() => handleCheckAll()"
                    :checked="checkAll"
                />
              </div>
              <td slot="select" slot-scope="{item}">
                <div>
                  <CInputCheckbox
                      id="checkBox"
                      :key="item.id"
                      label=""
                      :value="item.item"
                      name="listSelected"
                      @update:checked="() => handleSelect(item)"
                      :checked="listSelected.includes(item.id)"
                  />
                </div>
              </td>
              <td slot="name" slot-scope="{item}">
                <div> {{item.name || ''}}</div>
              </td>
              <td slot="email" slot-scope="{item}">
                <div> {{item.email || ''}}</div>
              </td>
              <td slot="shop_name" slot-scope="{item}">
                <div> {{item.shop.name || ''}}</div>
              </td>
              <td slot="created_at" slot-scope="{item}">
                <div>{{item.created_at || ''}}</div>
              </td>
              <td slot="action" slot-scope="{item}" class="text-center">
                <div>
                  <CButton @click="() => renderDetail(item)" ><CIcon name="cil-pencil" /></CButton>
                  <CButton @click="() => handleConfirmDelete(item)" ><CIcon name="cil-recycle" /></CButton>
                </div>
              </td>
            </DataTable>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ResourceApi from '../../api/resourceApi';
import DataTable from "@/components/DataTable";
import {WAREHOUSUE, SCREEN_KEY} from '@/utils/constance';
import Vue from "vue";

const apiUsers = new ResourceApi('users');
const apiShop = new ResourceApi('shops');
export default {
  name: 'Users',
  components: {DataTable},
  props: ['screen_key'],
  data () {
    return {
      WAREHOUSUE: WAREHOUSUE,
      SCREEN_KEY: SCREEN_KEY,
      users: [],
      loading: false,
      checkAll: false,
      listSelected: [],
      name: '',
      email: '',
      userShopId: this.$store.getters.user ? this.$store.getters.user.shop_id : '',
      shopOptions: [],
      shopSelected: '',
      paginationData: {},
      page: 1,
      perPage: 30,
      query: {page: this.page, per_page: this.perPage, screen_key: this.screen_key, type_data: this.typeDataSelected},
      key: Math.random(),
      optionsPerPage: [10, 20, 30, 40, 50, 100],
      fields:  [
        { key: 'select', label: '' },
        { key: 'name', label: '名前' },
        { key: 'email', label: 'Email' },
        { key: 'shop_name', label: '店舗名' },
        { key: 'created_at', label: '作成日付' },
        { key: 'action', label: '' },
      ],
      activePage: 1
    }
  },
  watch: {
    listSelected: function(newVal, oldVal) {
      let checkAll = true;
      this.users.map(s => {
        if(!newVal.includes(s.id)){
          checkAll = false;
        }
      })
      this.checkAll = checkAll;
    },
    users: function(newVal, oldVal) {
      let checkAll = true;
      this.users.map(s => {
        if(!this.listSelected.includes(s.id)){
          checkAll = false;
        }
      })
      this.checkAll = checkAll;
    }
  },
  created() {
    this.getData();
    if(this.screen_key === SCREEN_KEY.LIST_USERS){
      this.getShops()
    }
  },
  methods: {
    getShops () {
      // this.resetSelected()
      this.loading = true
      apiShop.all().then(response => {
        // console.log(response.data)
        let shops = [];
        response.data.data.map(s =>{
          shops.push({label: s.name, value: s.id})
        })
        this.shopOptions = shops;
        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },
    getData (params) {
      // this.resetSelected()
      this.loading = true
      this.key =  Math.random()
      this.query ={...this.query, ...params }
      apiUsers.index(this.query).then(response => {
        // console.log(response.data)
        this.users = response.data.data;
        this.paginationData = response.data;
        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },
    selectShopChange (val) {
      this.shopSelected =  val.target.value  ? parseInt(val.target.value) : ''
    },

    handleCheckAll() {
      let listSelected = this.listSelected
      if(this.checkAll){
        this.users.map(s => {
          listSelected = listSelected.filter(l => s.id !== l)
        })
      }else {
        this.users.map(s => {
          listSelected.push(s.id)
        })
      }
      this.listSelected = listSelected;
    },
    handleSelect(item){
      if(this.listSelected.includes(item.id)){
        this.listSelected = this.listSelected.filter(s => s !== item.id)
      }else {
        this.listSelected.push(item.id)
      }
    },
    setDateFilter (e) {
      this.filterDate = new Date(e.target.value).getTime()
    },
    filterData () {
      this.page = 1;
      let params = this.getParams({page: 1});
      this.getData(params);
    },

    getParams(param){
      let params = {
        name_like: this.name,
        email_like: this.email,
        shop_id_equal: this.shopSelected,
      }
      return  {...params, ...param}
    },

    handleConfirmDelete(user){
      let message = 'ユーザー情報を削除しますが宜しいでしょうか？'
      this.$confirm(
          {
            title: '確認',
            message: message,
            button: {
              no: 'いいえ',
              yes: 'はい'
            },
            callback: confirm => {
              if (confirm) {
                // ... do something
                this.handleDeleteUser(user)
              }
            }
          }
      )
    },

    handleDeleteUser(user){
      apiUsers.delete(user).then(response => {
        Vue.$toast.success('Deleted success');
        this.getData();
      }).catch(error => {
        // Vue.$toast.error('Error')
      }).finally(() =>{
        this.loading = false
      })
    },

    resetFilterData () {
      this.email = '';
      this.name = '';
      this.shopSelected = '';
      let params = {email_like: '', name_like:  '', shop_id_equal: ''}
      this.getData(params);
    },
    selectChange (val) {
      this.statusSelected =  val.target.value  ? parseInt(val.target.value) : ''
      // this.$router.push({ query: { page: val }})
    },
    selectChangePerpage (val) {
      this.perPage =  val  ? parseInt(val) : ''
      this.getData({per_page: val});
    },

    pageChange (val) {
      this.page = val;
      this.getData({page: val});
      // this.checkAll = false;
      // this.$router.push({ query: { page: val }})
    },
    renderDetail(item) {
      this.$router.push({path: `users/${item.id}`})
    }   ,
    renderAddUser() {
      this.$router.push({path: `users/create`})
    }
  }
}
</script>